import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "subbody modulewrap"
};
const _hoisted_2 = {
  key: 0,
  class: "newslist realwrap"
};
const _hoisted_3 = {
  key: 0,
  class: "newspage"
};
const _hoisted_4 = {
  class: "subcontitle"
};
const _hoisted_5 = ["innerHTML"];
import { FFmixin } from "@/mixin/mixin.js";
export default {
  __name: 'news',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      setTitle
    } = FFmixin();
    const route = useRoute();
    const FFdata = reactive({
      resData: {},
      infodata: [],
      infoDetail: {}
    });
    const getData = async () => {
      setTitle(3, 0, 0, () => {
        let id = route.params.id;
        if (id) {
          proxy.$common.fetch({
            'act': 'telfort_newsdetail',
            'exts': 'id=' + id,
            msg: ''
          }, res => {
            FFdata.infoDetail = res.infodata;
          });
        } else {
          getNewsList(0);
        }
      });
    };
    const getNewsList = cpage => {
      let k = 2;
      proxy.$common.fetch({
        'act': 'telfort_news',
        'exts': 'k=' + k + '&page=' + cpage,
        msg: ''
      }, res => {
        FFdata.infodata = res.infodata;
        FFdata.resData = res;
      });
    };
    onMounted(() => {
      getData();
    });
    watch(() => FFdata.resData.cpage, (newValue, oldValue) => {
      if (oldValue) {
        getNewsList(newValue);
      }
    });
    return (_ctx, _cache) => {
      const _component_fflink = _resolveComponent("fflink");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.$common.basic.isArrayExist(_unref(FFdata).infodata) ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FFdata).infodata, item => {
        return _openBlock(), _createElementBlock("dl", {
          key: item.id
        }, [_createElementVNode("dt", null, [_createVNode(_component_fflink, {
          info: item,
          root: "/news"
        }, null, 8, ["info"])]), _createElementVNode("dd", null, _toDisplayString(_ctx.$common.basic.dateFormat(item.publishdate)), 1)]);
      }), 128)), _unref(FFdata).resData.recordcount > 10 ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_el_pagination, {
        background: "",
        layout: "prev, pager, next",
        "current-page": _unref(FFdata).resData.cpage,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => _unref(FFdata).resData.cpage = $event),
        total: _unref(FFdata).resData.recordcount,
        "page-size": 10
      }, null, 8, ["current-page", "total"])])) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _ctx.$common.basic.isObjExist(_unref(FFdata).infoDetail) ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 1
      }, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$common.langInfo(_unref(FFdata).infoDetail.title, _unref(FFdata).infoDetail.title_en)), 1), _createElementVNode("div", {
        class: "subcon3 editor2",
        innerHTML: _ctx.$common.langInfo(_unref(FFdata).infoDetail.concn, _unref(FFdata).infoDetail.conen)
      }, null, 8, _hoisted_5)], 64)) : _createCommentVNode("", true)]);
    };
  }
};